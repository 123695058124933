















































import {
  computed, ref, useRoute, defineComponent
} from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';
import IncludeBlock from '~/slices/IncludeBlock/index.vue';

export default defineComponent({
  components: {
    // @ts-ignore
    IncludeBlock,
    SfHeading,
  },
  setup (props, context) {
    context.emit('changeStep', 4);
    const route = useRoute();

    const companyDetails = ref({
      name: 'SwiftOtter',
      street: '15954 S Mur-Len Rd #236',
      city: 'Olathe, KS, 66062',
      email: 'learning@swiftotter.com'
    });
    const orderNumber = computed(() => route.value.query.order ?? '');

    return {
      addBasePath,
      companyDetails,
      orderNumber
    };
  }
});
